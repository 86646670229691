import React, { useEffect, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import styled from 'styled-components/macro';
// import polylabel from '../utils/polylabel'
import polylabel from 'polylabel'
import { Polygon, FeatureGroup, Marker } from 'react-leaflet';
import { divIcon } from 'leaflet';
import 'leaflet/dist/leaflet.css';

import { useZoneCtx } from '../context/ZoneContext';
import { useAuthCtx } from '../context/AuthContext';
import { fetchAPI } from '../utils/fetchAPI';
import { LoadingView } from '../components/Custom/LoadingView';
import HeatMapOverlay from '../components/Maps/HeatMapOverlay';
import LeafletMap from '../components/Maps/components/LeafletMap';
import { Polylabel } from '../components/Maps/styled/styledMapInputs';

const Container = styled.div`
    width: 100%;
    height: 100%;
    position: relative;

    ${LeafletMap} {
        height: 100%;
        width: 100%;
    }
`;

const Maps = () => {
    console.log('map rerender');

    const defaultHeatMapValue = {
        column: undefined,
        period: undefined,
        slider: 0,
        data: undefined,
        ready: false,
    };
    const [heatMap, setHeatMap] = useState(defaultHeatMapValue);
    const [polygons, setPolygons] = useState();
    const [map, setMap] = useState();

    const polygonsStrokesRef = useRef();

    const [isLoading, setIsLoading] = useState(false);

    const { token } = useAuthCtx();
    const { zoneId } = useZoneCtx();

    useEffect(() => {
        function fCrds_adapter(fCrds) {
            fCrds.fields.length !== fCrds.coords.length && console.debug('%c BAD FCRDS FOR ZONE ' + zoneId, 'color: red')
            return fCrds.fields.map((field_id, index) => ({
                field_id,
                coords: fCrds.coords[index],
                center: polylabel([
                    fCrds.coords[index].map(point => [Number(point.lat), Number(point.lng)]),
                ]),
                colors: undefined,
                values: undefined,
            }));
        }

        function fetchPolygons(zoneId) {
            console.log('fetching polygons');
            setIsLoading(true);
            fetchAPI('fCrds', {
                v: '1.0',
                token: token,
                zone: zoneId,
            })
                .then(fCrds => {
                    setPolygons(fCrds_adapter(fCrds));
                    setHeatMap(defaultHeatMapValue);
                })
                .then(() => setIsLoading(false))
                .catch(error => alert(error.message));
        }

        fetchPolygons(zoneId);
    }, [zoneId]);

    return isLoading ? (
        <LoadingView />
    ) : (
        <Container>
            <LeafletMap setMap={setMap} tiles={['osm', 'satellite']}>
                <FeatureGroup ref={polygonsStrokesRef}>
                    {polygons?.map((field, index) => {
                        const color = field?.colors?.[heatMap.slider];
                        const value = field?.values?.[heatMap.slider] ?? '';
                        return (
                            <Polygon
                                className='polygon'
                                positions={field.coords}
                                key={'stroke' + index}
                                pathOptions={{
                                    color: 'black',
                                    fillColor: color ?? 'transparent',
                                    fillOpacity: 0.8,
                                }}
                            >
                                <Marker
                                    position={field.center}
                                    key={'label' + index}
                                    icon={divIcon({
                                        className: 'poly-label',
                                        html: renderToString(
                                            <Polylabel bg={color}>{value}</Polylabel>
                                        ),
                                    })}
                                />
                            </Polygon>
                        );
                    })}
                </FeatureGroup>
            </LeafletMap>
            <HeatMapOverlay
                {...{ polygons, setPolygons, heatMap, setHeatMap, defaultHeatMapValue }}
            />
        </Container>
    );
};

export default Maps;
