import { useState, useEffect, useRef } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { point as tPoint, featureCollection as tFeatureCollection } from '@turf/helpers';
import turfDistance from '@turf/distance';
import { useAuthCtx } from '../context/AuthContext';
import { useUserCtx } from '../context/UserContext';
import { fetchAPI } from '../utils/fetchAPI';
import { wsZone } from '../models/wsDataModels';

export function useVineyardEditor(isCreate) {
    const { token, logout, DATA_TOKEN } = useAuthCtx();
    const { dfltZone, userZones } = useUserCtx();
    const { zone_id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [isLoading, setLoading] = useState(false);
    const [allZones, setAllZones] = useState([]);
    const userZonesRef = useRef();

    // Hook Form
    const methods = useForm({
        defaultValues: defaultData,
        mode: 'onSubmit',
        resolver: yupResolver(schema),
    });

    const {
        control,
        reset,
        formState: { dirtyFields },
    } = methods;

    const {
        fields: zones,
        prepend,
        remove: removeZone,
    } = useFieldArray({
        control: control,
        name: 'zones',
    });

    // Load Data
    useEffect(() => {
        setLoading(true);

        if (isCreate) {
            fetchAPI('aZons', {
                v: '1.0',
                data_token: DATA_TOKEN,
            })
                .then(all_zones => {
                    setAllZones(all_zones);
                    location.state?.createNewOnOpen && prepend(emptyField);
                    setLoading(false);
                })
                .catch(err => {
                    console.error(err);
                    alert(err);
                    setLoading(false);
                });
        } else {
            // isEdit
            fetchAPI('zEdit', {
                v: '1.0',
                token,
                action: 'read',
                params: {},
            })
                .then(user_zones => {
                    userZonesRef.current = user_zones;
                    reset({
                        zones: user_zones
                            .filter(z => isCreate || z.id == zone_id)
                            .map(wsZone.createFromApi),
                    });
                    setLoading(false);
                })
                .catch(err => {
                    console.error(err);
                    alert(err);
                    setLoading(false);
                });
        }
    }, [isCreate, token, DATA_TOKEN, reset, zone_id, location.state?.createNewOnOpen, prepend]);

    // Submit
    const onSubmit = data => {
        console.debug('Form data:', data);

        const finalData = formatZoneData(data.zones);

        const hasNewFields = data.zones.some(z => z.fields.some(f => f.id?.includes?.('-')));

        console.debug('isCreate:', isCreate);

        if (isCreate) {
            createZones(finalData, allZones, token);
        } else if (hasNewFields) {
            addField(data, token);
        } else if (areYouSureEdit()) {
            editZone(finalData, token);
        }
    };

    // Handlers
    const handleAddZone = () => {
        if (zone_id) {
            reset(defaultData);
            navigate('/edit', { state: { createNewOnOpen: true } });
        } else {
            prepend(emptyField);
        }
    };

    const handleSelectZone = e => {
        navigate(`/edit/${e.target.selectedOptions[0].value}`);
    };

    const handleClear = () => {
        reset(isCreate ? defaultData : null);
    };

    const handleExit = () => {
        if (dfltZone) {
            navigate('/');
        } else {
            logout();
        }
    };

    // Derived isDirty
    let isDirty = !!Object.keys(dirtyFields).length;

    // Form Context Methods
    const formContext = { ...methods, isCreate, allZones, groupOptions, removeZone };

    return {
        zones,
        dfltZone,
        userZones,
        zone_id,
        formContext,
        onSubmit,
        isDirty,
        isLoading,
        defaultData,
        emptyField,
        handleAddZone,
        handleSelectZone,
        handleClear,
        handleExit,
    };
}

//#endregion
////////////////////////////
// SUBMIT FUNCTIONS
////////////////////////////
//#region

function createZones(finalData, allZones, token) {
    const tooFar = areInitZonesTooFar(finalData, allZones);

    if (tooFar || (tooFar === null && !areYouSureCreate())) {
        return;
    }

    fetchAPI('cFlds', {
        v: '2.1',
        token: token,
        zones: finalData,
    })
        .then(resp => alert(resp.resp_text))
        .catch(error => alert('Error: ' + error.message));
}

function editZone(finalData, token) {
    fetchAPI('zEdit', {
        v: '1.0',
        token,
        action: 'edit',
        params: finalData[0],
    })
        .then(resp => alert(resp.resp_text))
        .catch(error => alert('Error: ' + error.message));
}

function addField(data, token) {
    if (!areYouSureAdd()) {
        return;
    }

    const zone = data.zones[0];
    const { fields, ...zRest } = zone;

    const zoneGeo = tPoint([zRest.long, zRest.lat], { ...zRest });

    const newFields = fields.filter(f => f.id?.includes?.('-'));
    const features = newFields.reduce((acc, f) => [...acc, ...f.toGeoJSON()], []);
    features.push(zoneGeo);
    const fc = tFeatureCollection(features);

    fetchAPI('zEdit', {
        v: '1.0',
        token,
        action: 'add_new_fields',
        params: { featureCollection: fc },
    })
        .then(resp => alert(resp.resp_text))
        .catch(error => alert('Error: ' + error.message));
}

function formatZoneData(zones) {
    if (!zones) return [];

    const reversePoint = ([long, lat]) => [lat, long];

    const polygonToDots = polygon => {
        if (!polygon) return;
        return polygon.geometry.coordinates[0].slice(0, -1).map(([long, lat], index) => ({
            dotId: index.toString(),
            lat: lat.toString(),
            long: long.toString(),
        }));
    };

    const handleTimezone = (date, tz) => {
        if (!date) return null;
        moment.tz.setDefault(tz);
        const result = moment(
            new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, -1)
        ).unix();
        moment.tz.setDefault();
        return result;
    };

    const finalData = zones.map(zone => {
        return {
            ...zone,
            start_ts: handleTimezone(zone.start_ts, zone.tz),
            init_zone: zone.init_zone || null,
            fields: zone.fields.map(field => {
                return {
                    ...field.info,
                    polygon: polygonToDots(field.polygon) || null,
                    sensor: field.sensor && {
                        serial: field.sensor?.info?.serial,
                        point: reversePoint(field.sensor?.point?.geometry?.coordinates),
                        wind_offset: field.sensor?.info?.wind_offset,
                    },
                };
            }),
        };
    });

    console.debug('Submit data:', finalData);
    return finalData;
}

//#endregion
////////////////////////////
// USER DIALOGS
////////////////////////////
//#region

const areYouSureCreate = isCreate => window.confirm('Are you sure you want to create zone?');
const areYouSureEdit = isCreate => window.confirm('Are you sure you want to edit zone?');
const areYouSureAdd = () =>
    window.confirm(
        'You are adding a new field! All edits to existing fields will be lost!\nContinue?'
    );

function areInitZonesTooFar(finalData, all) {
    const zoneFarThreshold = 50;
    let farZoneWarnings = '';
    for (let zone of finalData) {
        if (zone.init_zone) {
            let initZone = all.find(z => z.zone_id === zone.init_zone);
            let dist = Math.round(
                turfDistance([initZone.long, initZone.lat], [zone.long, zone.lat])
            );
            if (dist > zoneFarThreshold) {
                farZoneWarnings += `${zone.name} - ${initZone.zone_name}: ${dist}km\n`;
            }
        }
    }
    if (farZoneWarnings) {
        return !window.confirm(
            'Are you sure you want to initialize zone with data from so far away?\n' +
                farZoneWarnings
        );
    }
    return null;
}

//#endregion
////////////////////////////
// YUP SCHEMA
////////////////////////////
//#region

yup.addMethod(yup.array, 'unique', function (propertyName, message) {
    return this.test('unique', message, function (list) {
        const errors = [];

        list.map(e => e[propertyName]).forEach((item, index, array) => {
            let appearanceCount = array.reduce((prev, curr) => prev + (curr === item), 0);
            if (appearanceCount > 1) {
                errors.push(
                    this.createError({
                        path: `${this.path}[${index}].${propertyName}`,
                        message,
                    })
                );
            }
        });

        if (errors.length) {
            throw new yup.ValidationError(errors);
        }
        return true;
    });
});

const yupRules = {
    name: yup
        .string()
        .trim()
        .max(16, "Can't be longer then 16 characters!")
        .required('This field is required')
        .matches(
            /^[\p{Letter}\p{Mark}\p{Number}\s_\-/]+$/gu,
            'Only letters, numbers, white space and - _ / allowed'
        ),
    decimal: yup
        .number()
        .typeError('You must specify a number')
        // accept comma as decimal separator
        .transform((_, num) => num && Number(num.toString().replace(/,/, '.'))),
    integer: yup //
        .number()
        .typeError('You must specify a number')
        .integer('Must be an integer'),
    hex: yup
        .string()
        .required('This field is required')
        .uppercase()
        .matches(/^[\dA-F]+$/, 'Hexadecimal number expected'),
};

const schema = yup.object().shape({
    zones: yup
        .array()
        .min(1, 'At least 1 zone is required')
        .of(
            yup.object().shape({
                name: yupRules.name,
                lat: yupRules.decimal
                    .min(-90, 'Latitude must be between -90 and 90')
                    .max(90, 'Latitude must be between -90 and 90'),
                long: yupRules.decimal
                    .min(-180, 'Longitude must be between -180 and 180')
                    .max(180, 'Longitude must be between -180 and 180'),
                alt: yupRules.decimal //
                    .min(0, 'Invalid altitude')
                    .max(5000, 'Invalid altitude'),
                tz: yup.string().trim().required('This field is required'),
                start_ts: yup.date().nullable().default(undefined),
                init_zone: yupRules.integer.nullable().default(undefined),
                fields: yup
                    .array() //
                    .min(1, 'At least 1 field is required')
                    .of(yup.object()),
            })
        )
        .unique('name', 'Zone names must be unique'),
});

//#endregion
///////////////////
// DEFAULT VALUES
///////////////////
//#region

const groupOptions = {
    1: '1 (Bouvier, Madeleine, Ortega...)',
    2: '2 (Muscat Ottonel, Zweigelt...)',
    3: '3 (Chardonnay, Pinot, Sauvignon Blanc...)',
    4: '4 (Riesling, Primitivo, Tempranillo...)',
    5: '5 (Syrah, Merlot, Cabernet Franc...)',
    6: '6 (Sangiovese, Cabernet Sauvignon...)',
    7: '7 (Airén, Trebbiano, Grenache...)',
    8: '8 (Muscat, Raboso, Plavac Mali...)',
    9: '9 (Bombino Bianco, Negroamaro...)',
};

const emptyField = {
    fields: [],
    tz: '',
    alt: null,
    long: null,
    lat: null,
    name: '',
};

const defaultData = {
    zones: [
        ///////////////////////////////////////////////////////////////////////////////////////////
        // {
        //     fields: [
        //         {
        //             id: '8eb1ed80-aa11-472e-9778-1c454dda36f8',
        //             polygon: {
        //                 type: 'Feature',
        //                 properties: {
        //                     name: 'Winessense',
        //                     variant: 'crno',
        //                     group: '3',
        //                     id: '8eb1ed80-aa11-472e-9778-1c454dda36f8',
        //                 },
        //                 geometry: {
        //                     type: 'Polygon',
        //                     coordinates: [
        //                         [
        //                             [20.78999, 44.405823],
        //                             [20.81912, 44.405701],
        //                             [20.816847, 44.392245],
        //                             [20.786901, 44.393165],
        //                             [20.78999, 44.405823],
        //                         ],
        //                     ],
        //                 },
        //             },
        //             sensor: {
        //                 info: {
        //                     serial: '11111111',
        //                     verification: '1111',
        //                 },
        //                 point: {
        //                     type: 'Feature',
        //                     properties: {
        //                         id: '4dc7cd26-7614-497a-afcd-267b021dd3cd',
        //                         fieldId: '8eb1ed80-aa11-472e-9778-1c454dda36f8',
        //                         serial: '11111111',
        //                     },
        //                     geometry: {
        //                         type: 'Point',
        //                         coordinates: [20.796168, 44.401655],
        //                     },
        //                 },
        //                 id: '4dc7cd26-7614-497a-afcd-267b021dd3cd',
        //                 fieldId: '8eb1ed80-aa11-472e-9778-1c454dda36f8',
        //             },
        //             info: {
        //                 name: 'Winessense',
        //                 variant: 'crno',
        //                 group: '3',
        //             },
        //         },
        //         {
        //             id: 'a21d9b5d-2938-41ac-a180-9ea5eb3e21e4',
        //             polygon: {
        //                 type: 'Feature',
        //                 properties: {
        //                     name: 'Winessense2',
        //                     variant: 'asd',
        //                     group: '5',
        //                     id: 'a21d9b5d-2938-41ac-a180-9ea5eb3e21e4',
        //                 },
        //                 geometry: {
        //                     type: 'Polygon',
        //                     coordinates: [
        //                         [
        //                             [20.799074, 44.411008],
        //                             [20.798387, 44.408556],
        //                             [20.812718, 44.409046],
        //                             [20.813662, 44.41251],
        //                             [20.799074, 44.411008],
        //                         ],
        //                     ],
        //                 },
        //             },
        //             sensor: {
        //                 info: {
        //                     serial: '11111111',
        //                     verification: '1111',
        //                 },
        //                 point: {
        //                     type: 'Feature',
        //                     properties: {
        //                         id: '3f7434cd-e31b-47ee-ae0a-0efc7d310dbb',
        //                         fieldId: 'a21d9b5d-2938-41ac-a180-9ea5eb3e21e4',
        //                         serial: '11111111',
        //                     },
        //                     geometry: {
        //                         type: 'Point',
        //                         coordinates: [20.802506, 44.409322],
        //                     },
        //                 },
        //                 id: '3f7434cd-e31b-47ee-ae0a-0efc7d310dbb',
        //                 fieldId: 'a21d9b5d-2938-41ac-a180-9ea5eb3e21e4',
        //             },
        //             info: {
        //                 name: 'Winessense2',
        //                 variant: 'asd',
        //                 group: '5',
        //             },
        //         },
        //     ],
        //     tz: 'Europe/Belgrade',
        //     alt: 1000,
        //     long: 20.8,
        //     lat: 44.4,
        //     name: 'zona zamfirova',
        // },
        ///////////////////////////////////////////////////////////////////////////////////
    ],
};

//#endregion
